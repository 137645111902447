import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';

import { MODULE_NAME } from './constants';

export const fetchDowngradeWorkFlowData = createAsyncThunk(
  `${MODULE_NAME}/fetchDowngradeWorkFlowData`,
  (plan: string) =>
    fetchJSON(`/monetization/downgrade_workflow.json?downgrade_to_plan=${plan}`)
);

export const acceptDiscount = createAsyncThunk(
  `${MODULE_NAME}/acceptDiscount`,
  (downgrade_to_plan: string) =>
    postJSON(`/monetization/accept_offer`, {
      downgrade_to_plan,
    })
);
