import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

type TaskType = 'Task::List' | 'Task::Item';

export type OccurrencePattern = 'does_not_repeat' | 'weekly' | 'daily';

export type ReducerBuilder = ActionReducerMapBuilder<InitialState>;

interface Task {
  id: number;
  name: string;
  currentLocationId?: number;
  type?: TaskType;
  tasks?: SingleTask[];
  end_at_secs?: number;
  start_at_secs?: number;
  description?: string;
  occurrence_day?: number | null;
  occurrence_pattern?: OccurrencePattern;
}

export interface SingleTask extends Task {
  assignee_id: number;
  assignee_type: string;
  assignee_name: string;
}

export interface TaskList extends Task {
  type: TaskType;
  assigneeId: number;
  assigneeType: string;
  tasks: SingleTask[];
  occurrence_pattern: OccurrencePattern;
}

export type Tasks = (TaskList | SingleTask)[];

export interface Pagination {
  page_number: number;
  total_pages: number;
  total_items: number;
}

export interface Department {
  id: string;
  name: string;
}

export interface Role {
  id: string;
  name: string;
}

export interface Employee {
  id: string;
  first_name: string;
  last_name: string;
}

export interface InitialState {
  hasPurchasedTaskManager: boolean;
  tasks: Tasks;
  pagination?: Pagination;
  isCreatingTask: boolean;
  isFetchingTasks: boolean;
  isFetchingDepartments: boolean;
  isFetchingRoles: boolean;
  isFetchingHistoryTasks: boolean;
  isFetchingTodayTasks: boolean;
  isFetchingEmployees: boolean;
  roles: Role[];
  historyTasks?: HistoryTasksResponse;
  todayTasks?: HistoryTasksResponse;
  employees: Employee[];
  departments: Department[];
}

export interface AddNewTaskButtonProps {
  endIcon?: string;
  size?: any;
}

export type AssignTo =
  | 'Organization::Department'
  | 'Organization::Role'
  | 'User'
  | 'Location'
  | '';
export type Repeating = 'daily' | 'weekly' | 'does_not_repeat' | '';

export interface InitialFormValues {
  id?: string;
  name: string;
  dueTime: string;
  dueDate: string;
  startTime: string;
  startDate: string;
  repeatOn: number[];
  description: string;
  assignee_type: AssignTo;
  deptRoleEmp: string | number;
  occurrence_pattern: Repeating;
}

export interface SubtaskProps extends SingleTask {
  index: number;
  assigneeType: AssignTo;
  assigneeName: string;
  currentLocationId: number;
  name: string;
  description?: string;
  _delete?: boolean;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  assigneeId: number;
}

export interface InitialTaskListFormValues {
  id: string;
  name: string;
  dueTime: string;
  dueDate: string;
  startTime: string;
  startDate: string;
  repeatOn: number[];
  tasks: SubtaskProps[];
  description: string;
  occurrence_pattern: Repeating;
}

export interface AddSingleTaskFormProps {
  task?: SingleTask;
  isOpen: boolean;
  currentLocationId: number;
  onClose: () => void;
  onDeleteSingleTask: (id: string) => Promise<any>;
  onCreateTask: (task: SingleTask) => Promise<any>;
  onUpdateSingleTask: (task: SingleTask) => Promise<any>;
  deptRoleEmployees: DeptRoleEmployee;
}

export interface PaginatedTaskResult {
  page_number: number;
  total_items: number;
  total_pages: number;
  tasks: Tasks;
}

export interface OnFetchTaskProps {
  pageNumber?: number;
  perPage?: number;
  locationId: number;
}

export interface TaskManagerV1Props {
  tasks?: Tasks;
  isFetchingTasks?: boolean;
  currentLocationId: number;
  pagination?: Pagination;
  onFetchTasks: (values: OnFetchTaskProps) => Promise<any>;
}

type GenericOption = {
  label: string;
  value: string | number;
};

export interface DeptRoleEmployee {
  'Organization::Department': GenericOption[];
  'Organization::Role': GenericOption[];
  User: GenericOption[];
  Location: GenericOption[];
  '': GenericOption[];
}

export interface AddTaskListViewProps {
  isFetchingTasks: boolean;
  onGetTaskById: (id: number) => any;
}

export type TaskManagerProductType = {
  isSubscribed: boolean;
  price: {
    amountInCents: number;
    priceSystemId: string;
  };
};

export enum TabValue {
  MANAGE = 0,
  HISTORY = 1,
  TODAY = 2,
}

export interface TableTask {
  id: number;
  name: string;
  description?: string;
  assignee_name?: string;
  assignee_type?: string;
  assignee_id?: number;
  start_at_secs: number;
  end_at_secs: number;
  location_id: number;
  completed_at_secs?: number;
  completed_by_user_id?: number;
  completed_by_user_name?: string;
  occurrence_pattern: string;
  notes: {
    id: number;
    description: string;
    user_name: string;
  }[];
  photos: {
    id: number;
    event_id: number;
    user_id: number;
    user_name: string;
    created_at_secs: number;
    width: number;
    height: number;
    original_url: string;
    preview_url: string;
  }[];
  attached_photos_count: number;
}

export interface HistoryTasksResponse {
  total_tasks: number;
  total_completed_tasks: number;
  task_events: TableTask[];
}
